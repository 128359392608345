import {useLogin} from '@web-auth/webauthn-helper';

const login = useLogin({
    actionUrl: '/webauthn/login/action',
    optionsUrl: '/webauthn/login/options'
});

let btn = document.getElementById('loginWithWebAuthn');
btn.addEventListener('click', function() {
    let u = document.getElementById('username').value;
    if (!u){
        alert('Enter your username first');
        return;
    }
    login({
        username: u
    })
        .then((response)=> window.location.reload() )
        .catch((error)=> {
            alert('There was a problem, are you sure this username has a WebAuthn USB Device registered');
        });
}, false);
